.mainContainer {
  padding: 5px;
}


.caption {

}

.footer {

}


.allTablesContainer {
 padding: 10px;
}

.tableContainer {
  margin: 15px;
}


.tableStyle {
    border: 1px solid black;
}

.redText {
  color: red;
}

.tempTable7 {

    width: 600px;
    height: 375px;
    background-color: #e9e9e9;
}

.tempTable15 {

    width: 1100px;
    height: 650px;
    background-color: #e9e9e9;
}

/* Loading indicators */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.component-loading {
  padding: 15px;
  text-align: center;
  background-color: rgba(245, 245, 245, 0.7);
  border-radius: 4px;
  margin: 10px 0;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


